.upload_icon__wrapper {
    font-family: #00796b;
    border: 1px dashed #00796b;
    color: #00796b;;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.3rem;
    margin-top: .5rem;
}
.upload__info {
    display: flex;
    font-family: #00796b;;
    justify-content: space-between;
    background-color: #00796b;;
    color: #FFF;
    align-items: center;
    border-radius: 0.3rem;
    padding: .5rem;
    margin-top: 1rem;
}