@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* Form Wrapper */
.form_wrapper {
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-wrap: wrap;
}

.input_message_wrapper {
    flex: 28;
    transition: 3s ease-in-out;
}

.chat_action_wrapper {
    flex: 1;
}

/* Chat Wrapper */
.chat_wrapper {
    width: 100%;
    height: 40rem;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    font-family: 'Roboto', sans-serif;
    transition: 2s ease-in-out;
    /* Enables scrolling when messages exceed the container height */
}

.content_wrapper {
    /* background: url('../../../../assets/images/chatbot_bg_2.png'); */
    background-image: linear-gradient(rgba(0, 0, 0, 0.41), rgba(0, 0, 0, 0.193)), url('../../../../assets/images/chatbot_bg_2.png');
    backdrop-filter: blur(5px);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: .5rem !important;
    overflow-y: auto;
}

.action_wrapper {
    background-image: linear-gradient(rgba(0, 0, 0, 0.41), rgba(0, 0, 0, 0.193)), url('../../../../assets/images/chatbot_bg_2.png');
        background-size: cover; /* Pattern size (auto) and gradient covers the entire area */
        background-repeat: no-repeat; /* Repeat pattern, no repeat for gradient */
        background-attachment: fixed;
        transition: '2s ease-in-out'
}

/* No Chat Data Section */
.no_chat_data {
    color: #FFF;
    height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: '2s ease-in-out';
    font-family: 'Poppins';
    font-weight: 500;
}

.question_card {
    min-width: 20rem;
    background-color: #ffffff24;
    padding: 1.2rem;
    padding-left: 2.4rem !important;
    border-radius: 1rem;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
}

.list_item {
    line-height: 2.1;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    padding: 0 .5rem;
    border-radius: 2rem;
}

.list_item:hover {
    background-color: #9494946b;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Chat Message Containers */
.user_chat, .genie_chat {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
}

/* Genie Messages - Left Aligned */
.genie_chat {
    justify-content: flex-start;
    flex-direction: row; /* Icon before message */
}

.genie_chat .message_text {
    background-color: #00796bb6;
    color: #fff;
    border-radius: 0 1rem 1rem 1rem;
    display: flex;
    align-items: center;
    gap: .5rem;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1);
    margin-bottom: .5rem !important;
}

.chat_icon_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: -1.2rem;
    /* align-items: flex-end; */
}

/* User Messages - Right Aligned */
.user_chat {
    justify-content: flex-end;
    flex-direction: row; 
    margin-right: .6rem;
}

.user_chat .message_text {
    /* background-color: #0d3f1b; */
    background-color: #ffffff;
    color: #00796b;
    border-radius: 1rem 0 1rem 1rem;
    box-shadow: 5px 5px 7px rgba(0, 0, 0, 0.1);
    font-weight: 500;
}

/* Icons */
.user_icon, .bot_icon {
    margin: 0.5rem;
    width: 30px;
    height: 30px;
}

.user_icon {
    margin-left: 0.5rem; /* Space between message and icon */
    margin-right: 0; /* Remove right margin since icon is on the end */
}

.bot_icon {
    margin-right: 0.5rem; /* Space between icon and message */
    margin-left: 0; /* Remove left margin */
}

.message_text {
    position: relative; /* Allow absolute positioning within this container */
    max-width: 94%;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    word-wrap: break-word;
    display: inline-block;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow */
}

.bar_chart_icon {
    position: absolute; /* Ensure the icon is positioned relative to the containing message box */
    top: 10px; /* Adjust this value to fine-tune vertical alignment */
    right: 10px; /* Adjust this value to fine-tune horizontal alignment */
    cursor: pointer;
    z-index: 1; /* Ensure it appears above other elements */
}

.message_text_wrapper {
    width: 100%;
}

.message_loding {
    color: #000;
}

.chart_chat_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    background-color: #fff;
    border-radius: .5rem;
}

.bot_title_wraper {
    background: url('../../../../assets/images/chatbot_bg_2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    font-family: 'Poppins' !important;    
}

.bot_title {
    font-size: 1.1em !important;
    font-weight: 500 !important;
    
}

.title_subheader {
    font-weight: 400 !important;
    font-size: 0.8em !important;
}

.topic_nav_wrapper {
    width: 100%;
    min-height: 50rem;
    background: 
    url('../../../../assets/images/chatbot_bg_2.png'), /* Pattern URL */
    linear-gradient(to bottom, #e3f6fc, #ffffff);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .15rem;
    flex-wrap: wrap;

}

.topic_nav {
    width: 17rem;
    height: 17rem;
    background: linear-gradient(to bottom right, #00796b, #4b8f83, #80b3a1, #a1d5c1);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);

    transition: ease-in-out 0.5s;
    display: flex;
    flex-direction: column;
}

.topic_nav:first-child {
    border-radius: 5rem 0 0 5rem;
}

.topic_nav_disabled {
    width: 15rem;
    height: 15rem;
    background-color: grey;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
    transition: ease-in-out 0.5s;
    display: flex;
    flex-direction: column;
    background-color: grey;
}

.topic_nav:last-child {
    border-radius: 0 5rem 5rem 0;
}

.topic_nav_disabled:last-child {
    border-radius: 0 5rem 5rem 0;
}

.topic_nav:hover {
    background: linear-gradient(to bottom right, #015c52, #4b8f83, #80b3a1, #84af9f);
    box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.nav_icon {
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.nav_title {
    width: 100%;
    height: 25%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    word-break: break-word;
    font-size: 1.3em;
    text-align: center;
}

.switch_wrapper {
    justify-content: center;
    display: flex;
    flex: 2;
    align-items: center;
    gap: 1rem;
    background-color: #ffffff3a;
    border-radius: 2rem;
    padding: 0 .5rem;
    color: #FFF;
    transition: 0.5s ease-in-out;
}

.switch_wrapper:hover {
    background-color: #ffffff57;
}

.switch_wrapper_CS {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: #fff;
    border-radius: 2rem;
    padding: 0 1rem;
    color: #00796b;
}

.option_wrapper {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.bar_chart_icon {
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.chat_chart_wrapper {
    position: relative; /* Allow absolute positioning within this container */
    width: 95%;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.5rem 1rem;
    word-wrap: break-word;
    display: flex;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    gap: 1rem;
    overflow-y: auto;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.kpi_title_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
}

.expand_options {
    font-size: 0.8em !important;
    color: #FFF;
    padding-top: 0.5rem;
    cursor: pointer;
}

.heading_font_style {
    font-family: 'Poppins';
    font-size: 1.1em !important;
    font-weight: 600 !important;
    color: #FFF;
}

/* Responsive Design */
@media (max-width: 600px) {
    .chat_wrapper {
        height: 30rem;
    }

    .message_text {
        max-width: 100%;
    }

    .user_icon, .bot_icon {
        width: 24px;
        height: 24px;
    }
}